<template>
  <div id="discussion">
    <v-container class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-card>
            <v-card-title>
              <div class="mt-1 mb-2">
                <h3>{{ discussion.title }}</h3>
              </div>
              <v-spacer />
              <v-btn
                text
                color="primary"
                :to="{ name: 'course-discussions' }"
                exact
                >Geri Dön</v-btn
              >
            </v-card-title>
            <v-card-subtitle>
              <span class="primary--text">{{ discussion.user.fullName }}</span>
              tarafından
              <span class="grey--text">{{ discussion.creationTime }}</span>
              tarihinde oluşturulmuştur.
            </v-card-subtitle>
            <v-card-text>
              <div v-html="discussion.text"></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="grey lighten-5 pb-6">
              <div class="mb-2">Tartışmaya katılmak ister misiniz?</div>
              <ckeditor
                :editor="editor"
                :config="editorConfig"
                v-model="newPost"
              />
              <v-btn
                class="mt-3"
                color="primary"
                type="button"
                :disabled="submitDisabled"
                @click="send"
                >Gönder</v-btn
              >
            </v-card-text>
            <v-divider></v-divider>

            <v-card v-for="(post, i) in posts" :key="i" flat class="mt-4">
              <v-card-title>{{ post.user.fullName }}</v-card-title>
              <v-card-subtitle>{{ post.creationTime }}</v-card-subtitle>
              <v-card-text v-html="post.text"></v-card-text>
              <v-card-actions v-if="deletePermission(post)">
                <v-spacer />
                <v-btn
                  link
                  text
                  small
                  @click="deletePost(post)"
                  color="primary"
                >
                  <v-icon small>delete</v-icon>Sil
                </v-btn>
              </v-card-actions>
              <v-divider v-if="i < posts.length - 1" />
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <component
        :is="dialogContent"
        @close="dialog = false"
        v-bind="selectedItemProps"
        @refresh="getInfos"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.enabled"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn icon @click="snackbar.enabled = false">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-snackbar>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export default {
  components: {
    Confirm,
    ckeditor: CKEditor.component
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      discussion: {},
      posts: [],
      newPost: "",
      submitDisabled: false,
      dialog: false,
      dialogContent: "",
      snackbar: {
        enabled: false,
        text: "",
        color: "info",
        timeout: 3000
      },
      editor: ClassicEditor,
      editorConfig: {
        language: "tr",
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "undo",
          "redo"
        ]
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"];
    },
    selectedItemProps() {
      if (this.dialogContent === "Add") {
        return {
          course: this.course
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    async getInfos() {
      try {
        const id = this.$route.params.id;
        const qsDiscus = await firebase
          .firestore()
          .collection("discussions")
          .doc(id)
          .get();

        if (qsDiscus.exists) {
          const discus = qsDiscus.data();
          discus.id = qsDiscus.id;
          discus.updateTime =
            discus.updateTime != null
              ? new Date(discus.updateTime.seconds * 1000).toLocaleString(
                  "tr-TR"
                )
              : discus.updateTime;
          discus.creationTime =
            discus.creationTime != null
              ? new Date(discus.creationTime.seconds * 1000).toLocaleString(
                  "tr-TR"
                )
              : discus.creationTime;

          // Tartışmayı oluşturan kişinin bilgilerini al
          const qsUser = await firebase
            .firestore()
            .collection("profiles")
            .where("uid", "==", discus.userId)
            .get();

          const user = qsUser.docs[0].data();

          discus.user = {
            fullName: user.firstName + " " + user.lastName,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles
          };

          this.discussion = discus;

          // Gönderileri al
          this.posts = [];

          const qsPosts = await firebase
            .firestore()
            .collection("discussionPosts")
            .where("discussionId", "==", discus.id)
            .orderBy("creationTime", "desc")
            .get();

          qsPosts.forEach(async docPost => {
            const post = docPost.data();
            post.id = docPost.id;
            post.updateTime =
              docPost.data().updateTime != null
                ? new Date(
                    docPost.data().updateTime.seconds * 1000
                  ).toLocaleString("tr-TR")
                : docPost.data().updateTime;
            post.creationTime =
              docPost.data().creationTime != null
                ? new Date(
                    docPost.data().creationTime.seconds * 1000
                  ).toLocaleString("tr-TR")
                : docPost.data().creationTime;

            // Gönderiyi oluşturan kişinin bilgilerini al
            const qsUserPost = await firebase
              .firestore()
              .collection("profiles")
              .where("uid", "==", post.userId)
              .get();

            const userPost = qsUserPost.docs[0].data();

            post.user = {
              fullName: userPost.firstName + " " + userPost.lastName,
              firstName: userPost.firstName,
              lastName: userPost.lastName,
              roles: userPost.roles
            };

            this.posts.push(post);
          });
        }
      } catch (error) {
        throw `Tartışma yüklenemedi: ${error}`;
      }
    },
    async send() {
      const confirmation = await this.$refs.confirm.open(
        "Emin misiniz?",
        "Mesajınızı göndermek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.submitDisabled = true;
        this.snackbar = {
          enabled: true,
          color: "info",
          text: "Mesajınız gönderiliyor. Lütfen bekleyin...",
          timeout: 0
        };

        try {
          const now = new Date(Date.now());
          const newPost = {
            courseId: this.course.id,
            discussionId: this.discussion.id,
            userId: this.currentUser.id,
            text: this.newPost,
            creationTime: now,
            updateTime: null
          };

          // Add discuss to database
          await firebase
            .firestore()
            .collection("discussionPosts")
            .add(newPost);

          setTimeout(() => {
            this.submitDisabled = false;
            this.snackbar.enabled = false;
            this.newPost = "";
            this.getInfos();
          }, 1000);

          this.snackbar = {
            enabled: true,
            color: "success",
            text: "Mesajınız gönderildi.",
            timeout: 3000
          };
        } catch (err) {
          this.submitDisabled = false;

          this.snackbar = {
            enabled: true,
            color: "error",
            text: `Mesaj gönderilemedi: ${err.code} ${err.message} ${err.details}`,
            timeout: 0
          };
        }
      }
    },
    deletePermission(item) {
      if (this.currentUser.roles.indexOf("admin") > -1) {
        return true;
      } else {
        return item.userId === this.currentUser.id;
      }
    },
    async deletePost(post) {
      const confirmation = await this.$refs.confirm.open(
        "Emin misiniz?",
        "Bu gönderiyi silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.snackbar = {
          enabled: true,
          color: "info",
          text: "Gönderi siliniyor. Lütfen bekleyin...",
          timeout: 0
        };

        try {
          // Gönderiyi sil
          await firebase
            .firestore()
            .collection("discussionPosts")
            .doc(post.id)
            .delete();

          setTimeout(() => {
            this.getInfos();

            // Clear snackbar
            this.snackbar = {
              enabled: false,
              color: "info",
              text: "",
              timeout: 3000
            };
          }, 1000);

          this.snackbar = {
            enabled: true,
            color: "success",
            text: "Gönderi silindi.",
            timeout: 0
          };
        } catch (err) {
          this.snackbar = {
            enabled: true,
            color: "error",
            text: err,
            timeout: 3000
          };
        }
      }
    }
  },
  mounted() {
    this.getInfos();
  }
};
</script>

<style lang="scss">
#discussion {
  .ck-content {
    min-height: 100px;
  }
}
</style>
